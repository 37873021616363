<template>
  <div class="page-role">
    <Navbar title="系统领域" needBack :needRight="false" ></Navbar>
    <div class="m-lr-40 wraper">
      <van-field v-model="keywords" left-icon="search" placeholder="搜索领域" :border="false"/>
      <van-list
      
      v-model="loading"
        :finished="finished"
        finished-text=""
        @load="onLoad"
        offset="100"
      >
        <RoleCard :list="list" needDesc source="role" redirect="roleDetail" :loading="loading"  :type="0"></RoleCard>
      </van-list>
    </div>
  
   
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
      
      ],
      loading: false,
      finished: false,
      keywords: "",
      queryKeys:{userId:localStorage.getItem("id"),title:'',type:0,pageNum:1,pageSize:8,cancel:false}
    };
  },
  created(){
   this.getRoleList()
  },
  watch:{
    keywords(cur){
      this.list=[]
      this.queryKeys.pageNum=1
      this.queryKeys.cancel=true
      this.queryKeys.title=cur
      this.getRoleList()
      
    }
  },
  methods: {
  async  getRoleList(){
    this.loading=true
     const res= await this.$api.role.getRoleList(this.queryKeys)
     this.list=[...this.list,...res.data.rows]
     this.loading=false
     if(this.queryKeys.pageSize>res.data.rows.length){
        this.finished=true
        return
      }
      
      this.queryKeys.pageNum++
    },
    onLoad() {
      this.finished = true;
    },
  },
};
</script>
<style lang="less" scoped>
.m-lr-40 {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}
.page-role {
  min-height: 100vh;
  background-color: #f5f7fb;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    height: 2.3rem;
    width: 100%;
    background: #ffffff;
  }
  .wraper {
    position: relative;
    z-index: 1;
  }
 ::v-deep .van-field {
    margin: 0.2rem 0 0.6rem;
    color: #8E8E93;
    font-size: 0.34rem;
    background-color: #f5f7fb;
    border-radius: 0.2rem;
    .van-field__left-icon{
      display: flex;
      align-items: center;
      color: #8e8e93;
    }
    .van-icon-search{
      font-size: .32rem;
    }
    .van-field__body{
      input{
        background-color: #f5f7fb;
       
      }
   

    }
  }
}
</style>